/*!
Theme Name: United Finance
Theme URI: http://underscores.me/
Author: Korpimedia
Author URI: http://korpimedia.fi
Description: United Finance Wordpress theme
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: united_finance
Tags:

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

United Finance is based on Underscores http://underscores.me/, (C) 2012-2016 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
*/

/*# Bootstrap
--------------------------------------------------------------*/
@import "../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap";

/*--------------------------------------------------------------
# Custom
--------------------------------------------------------------*/
@import "custom";
